import('./bootstrap')
  .catch((err) => console.error(err));

// Need to overwrite the Default toISODateString method on Date to make the string conversion,
// which happens when trying to post data, timezone agnostic.
(function () {
  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  Date.prototype.toISOString = function () {
    return (
      this.getFullYear() +
      '-' +
      pad(this.getMonth() + 1) +
      '-' +
      pad(this.getDate()) +
      'T' +
      pad(this.getHours()) +
      ':' +
      pad(this.getMinutes()) +
      ':' +
      pad(this.getSeconds())
    );
  };

  Date.prototype['stripTime'] = function () {
    const newDate: Date = new Date(this);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  };

  Date.prototype['maxValue'] = function () {
    const maxDate: Date = new Date();
    maxDate.setMonth(11);
    maxDate.setDate(31);
    maxDate.setFullYear(2999);
    return maxDate;
  };

  String.prototype['stripNonAsciiCharacters'] = function () {
    return this ? this.toString().replace(/[^\x20-\x7E]/g, '') : this;
  };

  Array.prototype['groupBy'] = function (array: Array<any>, field: any) {
    let groups = {};
    array.forEach(function (obj: any) {
      var group = JSON.stringify(field(obj));
      groups[group] = groups[group] || [];
      groups[group].push(obj);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  };
})();



